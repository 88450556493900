import { supabase } from "@lagora/utils/supabase";
import type { PostgrestError } from "@supabase/supabase-js";
import { useEffect, useRef, useState } from "react";
import Card from "./Card.component";
import HorizontalList from "./HorizontalList.component";

export default function LastResults() {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [error, setError] = useState<PostgrestError>();
  const [results, setResults] =
    useState<
      Array<{
        id: string;
        poll: {
          description: string;
          id: string;
          name: string;
        } | null;
      }>
    >();

  useEffect(() => {
    supabase
      .from("results")
      .select("id, poll: polls(id, name, description)")
      .limit(10)
      .then(({ data, error }) => {
        if (error) {
          setError(error);
        }

        if (data) {
          setResults(data);
        }
      });
  }, []);

  if (error) {
    return (
      <div style={{ height: wrapperRef.current?.clientHeight }}>
        <div className="alert alert-error">
          <svg
            className="stroke-current shrink-0 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>error</title>
            <path
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
          </svg>
          <span>Impossible de récupérer les derniers résultats.</span>
        </div>
      </div>
    );
  }

  return (
    <div
      className="w-[calc(100%+12px)]"
      data-testid="LastResults"
      ref={wrapperRef}
    >
      <HorizontalList>
        {results
          ? results.map(
              (result, resultIndex) =>
                result?.poll?.name && (
                  <div
                    className="inline-block min-w-[calc(100vw-36px)] sm:min-w-96"
                    data-testid={`LastResults__Item--${resultIndex}`}
                    key={result.poll?.id}
                  >
                    <Card
                      body={result.poll?.description}
                      href={`/results/${result.id}`}
                      title={result.poll.name}
                    />
                  </div>
                ),
            )
          : Array.from({ length: 10 }).map((_item, index) => (
              <div
                className="min-w-[calc(100vw-36px)] sm:min-w-96 h-[238px]"
                // biome-ignore lint/suspicious/noArrayIndexKey: Pas le temps
                key={index}
              >
                <Card loading />
              </div>
            ))}
      </HorizontalList>
    </div>
  );
}
